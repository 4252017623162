<template>
  <div class="sliderow left">
    <el-image class="look-img" :src="lookImg"></el-image>
    <div class="screen-list" v-if="isScreen">
      <el-image class="list-richImg" :src="require('../assets/title1.png')"></el-image>
      <el-image class="list-img" :src="require('../assets/richtitle1.png')"></el-image>
      <div class="list-text">{{richTitle}}</div>
    </div>

    <div class="vertical right" v-else>
      <el-image  :src="require('../assets/communicate.png')"></el-image>
      <el-image class="img1"  :src="require('../assets/title2.png')"></el-image>
      <div class="right-title">在线沟通</div>
      <div class="right-rich">提供安全的在线聊天功能可以和投资人进行高效的
        沟通和交流
      </div>
    </div>

    <div class="column-pos">
      <div class="column left1">
        <el-image class="bot-img" :src="require('../assets/bottom.png')"></el-image>
        <el-image class="up-img" :src="require('../assets/arrow-bottom.png')"></el-image>
      </div>
    </div>

  </div>

</template>
<script setup>
import { onMounted } from "vue";
import { defineProps } from 'vue';
const props = defineProps({
  isScreen: Boolean,
  lookImg: String, //左边图片
  // titleImg: String, //右边图片
  // richImg: String, //右边第二张图片
  title:String, //标题
  richTitle: String, //副标题
});

onMounted(()=>{
  console.log(props.richTitle)
})
</script>

<style scoped lang="less">
@media screen and (max-height: 900px) {
  //.el-image{
  //  height: 90%;
  //}
  .look-img{
    height: 70%;
  }
  .el-image__inner{
    height: 90%;
  }
  .asiText{
    height: 90%;
    top:100px;
    .screen-img{
      width: 400px;
      height: 330px;
    }
  }

  //.screen-img{
  //  width: 400px;
  //  height: 330px;
  //}
  .screen-img1{
    width: 606px;
    height: 141px
  }
  .creenW{
    height: 90%;
  }
  .el-image__inner{
    height: 90%;
  }
}
  .sliderow{
    width: 100%;
    display: flex;
    position:absolute;
    top:0px;
    align-items: center;
  }
  .left{
     width: 100%;
     height: 110%;
     justify-content: center;
     .el-img{
       width: 664px;
       height: 680px;
     }
     .screen-list{
       display: flex;
       flex-direction: column;
       margin-left: 87px;
       margin-right: 150px;
       .list-richImg{
         width: 410px;
         height: 169px;
       }
       .list-img{
         width: 356px;
         height: 34px;
         margin: 47px 0 32px;
       }
       .list-text{
         font-size: 20px;
         color: #333333;
         width: 450px;
         text-align: left;
         line-height: 30px;
       }
     }
   }

  .column-pos{
    position:absolute;
    right: 0
  }
  .column{
    display: flex;
    align-items: center;
    flex-direction: column;
    .column-img{
      width: 181px;
      height: 66px;
    }
  }
  .left1{
    margin-right: 56px;
  }
  .bot-img{
    width: 32px;height: 56px
  }
  .up-img{
    width: 22px;height: 35px;
    animation:init 2s ease-in infinite;
    margin-top: 46px;
  }
  @keyframes init {
    0%, 100% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(.8,0,1,1);
    }
    50% {
      transform: translateY(20%);
      animation-timing-function: cubic-bezier(0,0,.2,1);
    }
  }
  .vertical{
    display: flex;
    flex-direction: column;
    .el-img{
      width: 620px;
      height: 62px;
    }
    .img1{
      width: 435px;
      height: 70px;
      margin-top: -15px;
    }
    .right-title{
      font-weight: 600;
      font-size: 36px;
      color: #0D69EB;
      margin: 29px 0 39px;
    }
    .right-rich{
      font-size: 22px;
      color: #555555;
      width: 494px;
      text-align: right;
      line-height: 35px;
    }
    .img-right{
      width: 600px;height: 1001px;
      margin-right: 150px
    }
    .aliLeft{
      text-align: left;
    }
  }
</style>