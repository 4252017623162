<template>
  <div id="app">
<!--    <img src="./assets/logo.png">-->
<!--    <div>-->
<!--      <p>-->
<!--        If Element is successfully added to this project, you'll see an-->
<!--        <code v-text="'<el-button>'"></code>-->
<!--        below-->
<!--      </p>-->
<!--      <el-button>el-button</el-button>-->
<!--    </div>-->
    <index />
  </div>
</template>

<script setup>
// import DevicePixelRatio from '@/utils/devicePixelRatio.js';
import index from "../src/pages/index.vue"
import {onMounted} from "vue";

// created(()=>{
//   new DevicePixelRatio().init();
// })
onMounted(()=>{
  // new DevicePixelRatio().init();
  // bodyScale();
  // let t = window.devicePixelRatio;
  // console.log(t)
  // if (!!window.ActiveXObject || "ActiveXObject" in window) {
  //   if (t != 1) {
  //     // 如果在笔记本中用IE浏览器打开 则弹出提示
  //     alert('您的设备对显示进行放大导致页面显示不完全,请调整后打开/或用其他浏览器')
  //   }
  // } else {
  //   if (t != 1) {   // 如果进行了缩放，也就是不是1
  //     // let c = document.querySelector('body');
  //     // console.log(c)
  //     // c.style.zoom = -0.52 * t + 1.65;   // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
  //
  //   }
  // }
})
//     function bodyScale  () {
//       var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下			的可是区域宽度
//       var scale = devicewidth / 1300; // 分母——设计稿的尺寸
//       document.body.style.zoom = scale;//放大缩小相应倍数
//     }
// export default {
//   name: 'app',
//   components: {
//     index
//   },
//   setup(){
//     function bodyScale  () {
//       var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下			的可是区域宽度
//       var scale = devicewidth / 1300; // 分母——设计稿的尺寸
//       document.body.style.zoom = scale;//放大缩小相应倍数
//     }
//
//     onMounted(() => {
//       new DevicePixelRatio().init();
//       bodyScale();
//     })
//     return{
//       bodyScale
//     }
//   },
//
// }
</script>

<style>
html,body,#app  {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  margin: 0;
}



</style>
