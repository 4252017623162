import { createApp } from 'vue';
import ElementPlus from 'element-plus'; //全局引ui官网
import 'element-plus/dist/index.css';

import App from './App.vue';
// import { detectZoom } from '@/utils/devicePixelRatio.js';

// const m = detectZoom();
// document.body.style.zoom = 100 / Number(m);
// import 'babel-polyfill'
// import './utils/flexible';

// import './plugins/element.js'

const app = createApp(App)

app.use(ElementPlus)
app.mount('#app')
